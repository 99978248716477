<template>
  <div class=" height-100" style=" background-color: #F8F8F8;">
    <div style="background-color:#fff;padding-top: 0.8rem; padding: 0 1rem;">
      <div class="flex jcsb a-i-c p-10" style="padding: 1rem 0; border-bottom: 1px solid #DDD;">
        <div>微信账号</div>
        <div class="a-i-c flex" @click="init">
          <span style="color: #666; margin-right: 0.2rem;">{{ bindTex }}</span>
          <van-icon style="margin-top: 0.1rem;" name="arrow" color="#666" />
        </div>
      </div>
      <div class="flex jcsb a-i-c p-10" style="padding: 1rem 0; border-bottom: 1px solid #DDD;" @click="callPhone">
        <div>联系我们</div>
        <div class="flex">
          <span style="color: #666;margin-right: 0.2rem;">工作时间：9：00-21：00</span>
          <van-icon style="margin-top: 0.1rem;" name="arrow" color="#666" />
        </div>
      </div>
      <div class="flex jcsb a-i-c p-10" style="padding: 1rem 0;border-bottom: 1px solid #DDD;">
        <div>意见反馈</div>
        <div class="a-i-c flex">
          <span style="color: #666;">kf@shijieux.com</span>
        </div>
      </div>
      <div class="flex jcsb a-i-c p-10" style="padding: 1rem 0;">
        <div>版本</div>
        <div class="a-i-c flex">
          <span style="color: #666;">V{{ getVersion() }}</span>
        </div>
      </div>
    </div>

    <div @click="outLogin" class="outLogin">
      退出登录
    </div>
    <van-overlay :show="show">
      <div class="wrapper">
        <div class="block">
          <van-form @submit="onSubmit2">
            <!-- 输入任意文本 -->
            <div style="font-size: 1rem;font-weight: 600;">微信绑定手机号</div>
            <van-field v-model="tokenParams.mobile" type="tel" placeholder="输入手机号" disabled
              style="border-radius: 2rem; background-color: #f8f8f8;margin-bottom: 1rem;margin-top: 1rem;" />

            <van-field type="digit" v-model="tokenParams.code" placeholder="输入验证码" :rules="[{ required: true }]"
              style="border-radius: 2rem; background-color: #f8f8f8;padding: 0.5rem,1rem; margin-top: 0.5rem;">
              <template #button>
                <div style="display: flex;flex-direction: row; align-items: center;">
                  <div style="background-color:#D0D0D0; height: 1.5rem;width: 0.02rem; "></div>
                  <div v-if="countdown2" style="color:#D33B2E;font-size: 1rem;margin-left: 0.5rem;" @click="getCode1">{{
                    text2
                  }}</div>
                  <div v-else style="color:#D33B2E;font-size: 1rem;margin-left: 0.5rem;">倒计时:{{ count }}秒</div>
                </div>
              </template>
            </van-field>
            <div>
              <div class="foot-btn" native-type="submit" @click="onSubmit2">确认</div>
            </div>
          </van-form>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Notify } from "vant";
import config from '../../../package.json'
// 微信授权登陆地址
const WX_AUTH_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize?';
// 重定向参数-固定写法
const REDIRECT = '#wechat_redirect';
export default {
  name: "",
  props: {},
  data() {
    return {
      count: "",//60秒倒计时
      countdown2: true,
      userInfo: null,
      text2: "发送验证码",
      show: false,
      openId: null,
      params: {
        // appid: 'wxbaec9d10a9963029', // 公众号 APP ID 
        // appid: 'wx746300fc73d7d2ba',
        appid: process.env.VUE_APP_WX_APPID,
        redirect_uri: `${encodeURI(process.env.VUE_APP_WX_BACK + 'Personal')}`,
        response_type: 'code', // 固定写法
        scope: 'snsapi_userinfo', // snsapi_base 静默授权获取 open id ；snsapi_userinfo 需要用户授权，获取详细信息
      },
      code: '', // 微信返回 code
      tokenParams: {
        code: '',
        openId: '',
        mobile: '',
      },
      bindWx: true,
      bindTex: '',
      Fromvnode: null
    };
  },
  components: { config },
  created() {
    // 获取地址参数
    const params = new URLSearchParams(location.search);
    this.code = params.get('code');
    if (this.code) {
      this.initToken()
    }
  },
  methods: {
    getCode1() {
      this.$api
        .sendSms(this.tokenParams.mobile)
        .then((res) => {
          if (res.code == 1) {
            this.back()
            this.$Toast(res.msg);
            return
          }
          this.changeButton1()

        })
        .catch((err) => {
        });
    },
    changeButton1() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.countdown2 = false;
        this.text2 = "重新发送"
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.countdown2 = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    init() {
      if (!this.bindWx) {
        return
      }
      // 访问地址
      const access_url = WX_AUTH_URL + `${new URLSearchParams(this.params)}` + REDIRECT;
      // 这些需要判断没有 code 情况拉起授权登陆，有就结束放在重复拉起授权登陆
      if (!this.code) {
        location.href = access_url;
      }
    },
    initToken() {
      this.$api
        .codeCallback(this.code)
        .then((res) => {
          if (res.code == 0) {
            this.tokenParams.openId = res.data.openid
            if (res.data.bindFlag != '0') {  // list.length   < 5
              this.show = true
            } else {
              this.back()
              this.$Toast('该微信已经绑定五个手机号');
            }
          } else {
            this.back()
            this.$Toast(res.msg);
          }

        })
        .catch((err) => {
          this.$Toast(err);
        });
    },
    back() {
      this.$router.back();
    },
    onSubmit2() {
      this.$api
        .bindUserByOpenId(this.tokenParams)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          if (res.code == 0) {
            localStorage.setItem("openId", this.tokenParams.openId);
          }
          this.$Toast(res.msg);
          this.show = false
          this.back()

        })
        .catch((err) => {
          this.$Toast(err);
          this.show = false
          this.back()
        });
    },
    confirmWxByMobile() {
      this.$api
        .confirmWxByMobile(this.tokenParams.mobile)
        .then((res) => {
          if (res.code == 0) {
            this.bindWx = res.data
          }
          if (!this.bindWx) {
            this.bindTex = '已绑定'
          } else {
            this.bindTex = '未绑定'
          }

        })
        .catch((err) => {
        });

    },
    // 拨打电话
    callPhone() {
      window.location.href = "tel:400-001-6621";
    },

    outLogin() {
      localStorage.removeItem('access_token');
      this.$router.replace("/");
    },

    goback() {
      this.$router.back();
    },
    getVersion() {
      return config.version
    }
  },
  mounted() {
    this.tokenParams.mobile = localStorage.getItem('loginphone')
    this.confirmWxByMobile()
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.Fromvnode = from
  //   })
  // },
  // beforeRouteLeave(to, from, next) {
  //   if (to.path !== '/Cashier' || to.path !== '/MovieCashier') {
  //     this.Fromvnode.meta.keepAlive = false
  //   }
  //   next()
  // },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.outLogin {
  color: white;
  background-image: linear-gradient(left, #ffcf7a 0%, #ffa976 100%);
  border-radius: 1.5rem;
  margin: 2.5rem 1rem;
  padding: 0.8rem 0;
  text-align: center;
}

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}

.foot-btn {
  width: 100%;
  margin-top: 2.5rem;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  border-radius: 1.5rem;
  color: #fff;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  font-size: 1.1rem;
}

.block {
  width: 100%;
  height: 17rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: #fff;
}
</style>